// 'use client'

import clsx from 'clsx'

import { Tab } from '@headlessui/react'

import { Container } from '~/components/landing/Container'

interface Feature {
  title: React.ReactNode
  description: string
  summary: string
  image: string
  icon: string
}

interface Props {
  title: string
  subtitle: string
  features: Feature[]
}

function Feature({
  feature,
  isActive,
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'> & {
  feature: Feature
  isActive: boolean
}) {
  // const Icon = Icons[feature.icon]

  return (
    <div
      className={clsx(className, !isActive && 'opacity-75 hover:opacity-100')}
      {...props}
    >
      <div
        className={clsx('w-9 rounded-lg', isActive ? 'bg-blue-100' : 'bg-none')}
      >
        {feature.icon.startsWith('<svg') ? (
          <svg
            dangerouslySetInnerHTML={{ __html: feature.icon }}
            className="h-9 w-9 p-1"
          />
        ) : (
          <span
            aria-hidden="true"
            className="h-9 w-9 text-2xl p-1 text-center block"
          >
            {feature.icon}
          </span>
        )}
      </div>
      <h3
        className={clsx(
          'mt-6 text-sm font-medium',
          isActive ? 'text-blue-600' : 'text-slate-600'
        )}
      >
        {feature.title}
      </h3>
      <p className="mt-2 font-display text-xl text-slate-900">
        {feature.description}
      </p>
      <p className="mt-4 text-sm text-slate-600">{feature.summary}</p>
    </div>
  )
}

function FeaturesMobile(props: { features: Feature[] }) {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {props.features.map((feature) => (
        <div key={feature.description}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
            <div className="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
              <img
                className="w-full"
                src={feature.image}
                alt=""
                sizes="52.75rem"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

function FeaturesDesktop(props: { features: Feature[] }) {
  return (
    <Tab.Group as="div" className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <Tab.List className="grid grid-cols-3 gap-x-8">
            {props.features.map((feature, featureIndex) => (
              <Feature
                key={feature.description}
                feature={{
                  ...feature,
                  title: (
                    <Tab className="ui-not-focus-visible:outline-none">
                      <span className="absolute inset-0" />
                      {feature.title}
                    </Tab>
                  ),
                }}
                isActive={featureIndex === selectedIndex}
                className="relative"
              />
            ))}
          </Tab.List>
          <Tab.Panels className="relative mt-20 overflow-hidden rounded-4xl bg-slate-200 px-14 py-16 xl:px-16">
            <div className="-mx-5 flex">
              {props.features.map((feature, featureIndex) => (
                <Tab.Panel
                  static
                  key={feature.description}
                  className={clsx(
                    'px-5 transition duration-500 ease-in-out ui-not-focus-visible:outline-none',
                    featureIndex !== selectedIndex && 'opacity-60'
                  )}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={featureIndex !== selectedIndex}
                >
                  <div className="w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                    <img
                      className="w-full"
                      src={feature.image}
                      alt=""
                      sizes="52.75rem"
                    />
                  </div>
                </Tab.Panel>
              ))}
            </div>
            <div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10" />
          </Tab.Panels>
        </>
      )}
    </Tab.Group>
  )
}

export function SecondaryFeatures(props: Props) {
  return (
    <section
      id="secondary-features"
      aria-label="Features for simplifying everyday business tasks"
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
            {props.title}
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            {props.subtitle}
          </p>
        </div>
        <FeaturesMobile features={props.features} />
        <FeaturesDesktop features={props.features} />
      </Container>
    </section>
  )
}
