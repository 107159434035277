/**
 * Convert to builder.io
 */
import { Builder, builder, BuilderComponent } from '@builder.io/react'
import type { BuilderContent } from '@builder.io/sdk'
import type { DataFunctionArgs, MetaFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'

import { CallToAction } from '~/components/landing/CallToAction'
import { Faqs } from '~/components/landing/Faqs'
import { Footer } from '~/components/landing/Footer'
import { Header } from '~/components/landing/Header'
import { Hero } from '~/components/landing/Hero'
import { Pricing } from '~/components/landing/Pricing'
import { PrimaryFeatures } from '~/components/landing/PrimaryFeatures'
import { SecondaryFeatures } from '~/components/landing/SecondaryFeatures'
import { Testimonials } from '~/components/landing/Testimonials'
import { getSocialMetas, host } from '~/utils'

// import '~/components/builder-registry'

builder.init('4c50752ba8b6478d8d6b9510fbc53133')

Builder.registerComponent(Header, {
  name: 'Header',
})
Builder.registerComponent(Hero, {
  name: 'Hero',
  inputs: [
    { name: 'title', type: 'longText', defaultValue: 'StaticKit' },
    { name: 'titleHighlight', type: 'text' },
    {
      name: 'subtitle',
      type: 'longText',
      defaultValue: 'for small businesses',
    },
    {
      name: 'callToAction',
      type: 'text',
      defaultValue: 'Get started for free',
    },
  ],
})
Builder.registerComponent(PrimaryFeatures, {
  name: 'PrimaryFeatures',
  inputs: [
    {
      name: 'title',
      type: 'text',
      defaultValue: 'Snipbot is Your Edge in a Dynamic Sales Environment',
    },
    {
      name: 'subtitle',
      type: 'longText',
      defaultValue:
        'Stay connected and proactive as your contacts embark on new career journeys, turning each update into a strategic sales move.',
    },
    {
      name: 'features',
      type: 'list',
      subFields: [
        { name: 'title', type: 'text', required: true },
        { name: 'description', type: 'longText', required: true },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          required: true,
        },
      ],
    },
  ],
})
Builder.registerComponent(SecondaryFeatures, {
  name: 'SecondaryFeatures',
  inputs: [
    {
      name: 'title',
      type: 'text',
      defaultValue: 'Simplify everyday business tasks.',
    },
    {
      name: 'subtitle',
      type: 'longText',
      defaultValue:
        'Because you’d probably be a little confused if we suggested you complicate your everyday business tasks instead.',
    },
    {
      name: 'features',
      type: 'list',
      subFields: [
        { name: 'title', type: 'text', required: true },
        { name: 'description', type: 'longText', required: true },
        { name: 'summary', type: 'longText', required: true },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          required: true,
        },
        {
          name: 'icon',
          type: 'text',
          // enum: ['InboxStackIcon', 'AcademicCapIcon', 'SwatchIcon'],
          required: true,
        },
      ],
    },
  ],
})
Builder.registerComponent(CallToAction, {
  name: 'CallToAction',
  inputs: [
    { name: 'title', type: 'longText', defaultValue: 'Get started today' },
    {
      name: 'subtitle',
      type: 'longText',
      defaultValue:
        'It’s time to take control of your books. Buy our software so you can feel like you’re doing something productive.',
    },
    {
      name: 'callToAction',
      type: 'text',
      defaultValue: 'Get 6 months free',
    },
  ],
})
Builder.registerComponent(Testimonials, {
  name: 'Testimonials',
  inputs: [
    {
      name: 'title',
      type: 'text',
      defaultValue: 'Snipbot is Your Edge in a Dynamic Sales Environment',
    },
    {
      name: 'subtitle',
      type: 'longText',
      defaultValue:
        'Stay connected and proactive as your contacts embark on new career journeys, turning each update into a strategic sales move.',
    },
    {
      name: 'testimonials',
      type: 'list',
      subFields: [
        { name: 'content', type: 'longText', required: true },
        { name: 'authorName', type: 'text', required: true },
        { name: 'authorTitle', type: 'text', required: true },
        {
          name: 'authorImage',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          required: true,
        },
      ],
    },
  ],
})
Builder.registerComponent(Pricing, {
  name: 'Pricing',
})
Builder.registerComponent(Faqs, {
  name: 'Faqs',
  inputs: [
    {
      name: 'title',
      type: 'text',
      defaultValue: 'Frequently Asked Questions',
    },
    {
      name: 'subtitle',
      type: 'longText',
    },
    {
      name: 'faqs',
      type: 'list',
      subFields: [
        { name: 'question', type: 'longText', required: true },
        { name: 'answer', type: 'longText', required: true },
        {
          name: 'column',
          type: 'string',
          enum: [
            { label: 'First', value: 1 },
            { label: 'Second', value: 2 },
            { label: 'Third', value: 3 },
          ],
          required: true,
        },
      ],
    },
  ],
})
Builder.registerComponent(Footer, {
  name: 'Footer',
})

/**
 * @cached
 * xxxx
 */

export { links } from '~/links'

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data?.content) return getSocialMetas({ url: `${host()}/` })
  const { title, description } = data?.content.data ?? {}

  return getSocialMetas({ url: `${host()}/`, title, description })
}

export async function loader({ request }: DataFunctionArgs) {
  const content = (await builder
    .get('page', {
      userAttributes: {
        urlPath: '/',
      },
    })
    .toPromise()) as BuilderContent

  // Verify the user is previewing or editing in Builder
  // const isPreviewing = new URL(request.url).searchParams.has('builder.preview')

  return { content }
}

export default function Index() {
  const { content } = useLoaderData<typeof loader>()
  return (
    <div className="flex flex-col bg-slate-50">
      <main>
        {/* @ts-expect-error - type issue between loader and component */}
        <BuilderComponent model="page" content={content} />
      </main>
    </div>
  )
}
