import { Button } from '~/components/landing/Button'
import { Container } from '~/components/landing/Container'

interface Props {
  title: string
  subtitle: string
  callToAction: string
}
export function CallToAction(props: Props) {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src="/landing/background-call-to-action.jpg"
        alt=""
        width={2347}
        height={1244}
      />
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            {props.title}
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            {props.subtitle}
          </p>
          <Button to="/products/signals/join" color="white" className="mt-10">
            {props.callToAction}
          </Button>
        </div>
      </Container>
    </section>
  )
}
