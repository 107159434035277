// import Image from 'next/image'

import { Container } from '~/components/landing/Container'

interface Props {
  title: string
  subtitle: string
  faqs: {
    question: string
    answer: string
    column: 1 | 2 | 3
  }[]
}
export function Faqs(props: Props) {
  const faqs = (props.faqs ?? []).reduce(
    (acc, faq) => {
      acc[faq.column - 1].push(faq)
      return acc
    },
    [[], [], []] as [Props['faqs'], Props['faqs'], Props['faqs']]
  )
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src="/landing/background-faqs.jpg"
        alt=""
        width={1558}
        height={946}
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            {props.title ?? 'Frequently asked questions'}
          </h2>
          {props.subtitle ? (
            <p className="mt-4 text-lg tracking-tight text-slate-700">
              {props.subtitle}
            </p>
          ) : null}
        </div>
        <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
